import React from 'react'
import { Box,
    Text,
    Heading,
    Modal,
    Flex,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure } from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { RichText } from 'prismic-reactjs';

const TeamModal = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    return (
        <>
            <Box w={{sm: '100%', md: '33%'}} p="12" onClick={onOpen} style={{cursor: 'pointer'}}>
            <Box rounded="xl" overflow="hidden">
                <GatsbyImage 
                    image={getImage(props.image)}
                    alt=""
                />
            </Box>
            <Box textAlign="center" mt="4">
                <Text fontSize="1.75rem" color="steel.700" fontWeight="600" mb="1">
                {props.teamMemberName}
                </Text>
                <Text fontSize="1.55rem" color="steel.700">
                    {props.teamMemberTitle}
                </Text>
            </Box>
        </Box>

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{zIndex: 'unset', textTransform: 'uppercase'}}>{""}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
              <Flex flexWrap="wrap" justifyContent={{sm: "flex-start", md: "center"}}>
                  <Box w={{sm: '50%', md: '25%'}} rounded="xl" overflow="hidden" height="100%">
                      <GatsbyImage image={getImage(props.image)}
                        alt=""
                        />
                  </Box>
                  <Box w={{sm: '100%', md: '70%'}} ml={{sm: 0, md: '20px'}}>
                      <Heading as="h2">
                          {props.teamMemberName}
                      </Heading>
                      <Heading as="h3" fontSize="1rem">
                          {props.teamMemberTitle}
                      </Heading>
                  <Text>{props.modalBody}</Text>
                  </Box>
              </Flex>
            
          </ModalBody>
        </ModalContent>
      </Modal>
        </>
    )
}

const TeamMember = (props) => {
    return (
        <>
        <TeamModal 
        image={props.image}
        teamMemberName={props.teamMemberName}
        teamMemberTitle={props.teamMemberTitle}
        modalBody={<><RichText render={props.modalContent} /></>}
            />
    
        </>
    )
}

export default TeamMember
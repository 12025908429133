import React from 'react'
import {
    Box,
    Container,
    Flex
} from '@chakra-ui/react'

import { StaticImage } from 'gatsby-plugin-image'

const AboutImages = () => (
    <Box>
        <Container maxW="container.xl">
            <Flex justifyContent="space-between" flexWrap="wrap">
                <Box w={{sm: '100%', md: '50%'}} p="2">
                    <StaticImage 
                        src="../../images/manufacturing.jpg"
                        alt="medical device manufacturing"
                        placeholder="blurred"
                    />
                </Box>
                <Box w={{sm: '100%', md: '50%'}} p="2">
                    <StaticImage 
                        src="../../images/offices.jpg"
                        alt="medical device manufacturing"
                        placeholder="blurred"
                    />
                </Box>
            </Flex>
        </Container>
    </Box>
)

export default AboutImages
import React from 'react'
import {
    Box,
    Flex,
    Container,
    Heading
} from '@chakra-ui/react'
import IrisBackground from './IrisBackground'

const HomeHero = (props) => {

    return (
      <Box h="40vh" className="home-hero" mt={{ sm: '40px', md: 0}}>
      <Flex position="absolute"
        justifyContent="flex-end"
        alignItems="center"
        h="90%"
        width="100%"
      >
        <Box w={{md: '60%', lg: '35%', xl: '45%'}}>
          <IrisBackground />
          </Box>
      </Flex>
      <Flex h="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
            //zIndex="50"
          >
          <Container maxWidth="container.lg">
          <Heading as="h1" textAlign="center" variant="page-title" fontSize={{sm: '3rem', md: '4rem'}} mb="4">
        {props.pageTitle}
      </Heading>
      <Heading as="h2" textAlign="center" variant="sub-title">
        {props.pageSubTitle}
      </Heading>
            {/* <Heading as="h1" fontSize={{ sm: '2rem', md: '3rem'}} textAlign="center" fontWeight="600">
              About Us
            </Heading>
            <Heading as="h2" textAlign="center" fontWeight="100" fontSize={{ sm: '1.75rem', md: '2.5rem'}} >
            Our mission is to provide best-in-class solutions for emerging neuromodulation and neurodiagnostic applications.
            </Heading> */}
            {/* <Flex justifyContent="center" flexWrap="wrap">
            <Box p={{sm: '2', md: '8'}}>
                <ContactModal />
            </Box>
            <Box p={{sm: '2', md: '8'}}>
                <CtaButton 
                    variant="ctaButtonOutline"
                    btnText="Our Services"
                    btnLink="/services"
                />
            </Box>
            </Flex>             */}
          </Container>
          </Flex>
    </Box>
    )
}

export default HomeHero
import React from "react"
import Layout from '../components/layout'
import { Container } from '@chakra-ui/react'
import Seo from "../components/seo"
import AboutHero from "../components/AboutPage/AboutHero"
import AboutImages from "../components/AboutPage/AboutImages"
import NeurotechApplicationsBlock from "../components/AboutPage/NeurotechApplicationsBlock"
import CompanyInfoBlock from "../components/AboutPage/CompanyInfoBlock"
import TeamBlock from "../components/AboutPage/TeamBlock"
import ConferencesBlock from "../components/Conferences/ConferencesBlock"

const AboutPage = (props) => {
 
  return (
    
  <Layout
    annoucementDisplay="none"
  >
  <Seo title="Iris Biomedical | A Ripple Ventures Company" />
    <AboutHero 
      pageTitle="About Us"
      pageSubTitle="Our mission is to provide best-in-class solutions for emerging neuromodulation and neurodiagnostic applications." />
    
    <AboutImages />
    <NeurotechApplicationsBlock />
    <CompanyInfoBlock />
    <TeamBlock />
    <Container maxWidth="container.lg">
      <hr />
    </Container>
    <ConferencesBlock />


  </Layout>
  )
}


export default AboutPage

import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
    Box,
    Heading,
    Text,
    Button,
    Link,
    Flex
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

const Conference = (props) => {
    return (
        <>
            <Box my="4" p={{sm: '4', md: '16'}} style={{boxShadow: '0px 3px 6px #00000029', borderRadius: '20px'}}>
                <Heading fontSize={{sm: '2rem', md: '2.25rem'}}>
                    {props.meetingTitle}
                </Heading>
                <Text fontSize="1.5rem" color="steel.700" fontWeight="300">
                    {props.meetingDate}
                </Text>
                <Text>
                    Link:{' '}
                    <a href={props.meetingLink} target="_blank" rel="noopener noreferrer">
                        {props.meetingLink}
                    </a>
                </Text>
                <Flex justifyContent='flex-end'>
                    {props.postLink && ( <Box><Link as={GatsbyLink} to={props.postLink}><Button variant="ctaButtonOutline">Read More <ChevronRightIcon /></Button></Link></Box> )}
                </Flex>

            </Box>
        </>
    )
}

export default Conference

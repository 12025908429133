import React from 'react'
import {
    Container,
    Box,
    Text,
    Heading
} from '@chakra-ui/react'

import { useStaticQuery, graphql } from 'gatsby'

import Conference from './Conference'

const ConferencesBlock = () => {
   
    const data = useStaticQuery(graphql`
    {
      allPrismicEvent {
        edges {
          node {
            data {
              title {
                text
              }
              event_location {
                text
              }
              event_date {
                text
              }
              content {
                raw
              }
              meeting_link {
                url
                target
              }
            }
            url
            uid
          }
        }
      }
    }
    
  `)

  const meetingData = data.allPrismicEvent.edges
      return (
        <>
             <Container maxWidth="container.xl" my={20} p={{sm: 4, md: 8}}>
            <Box w={{sm: '100%', md: '100%'}} id="events">
            <Text variant="sub-head" textAlign="center">
                Conferences
            </Text>
            <Heading as="h2" fontWeight="600" textAlign="center" mb="2">
            We attend a variety of industry events each year
            </Heading>
            <Text fontSize="1.5rem" color="steel.700" fontWeight="300" textAlign="center">
                Check the list below for a conference or event near you.
            </Text>
            </Box>
            <Container maxW="container.lg">
                {meetingData.map((meetingItem) => {
                    return (
                        <Conference
                            meetingTitle={meetingItem.node.data.title.text}
                            meetingDate={meetingItem.node.data.event_date.text}
                            meetingLink={meetingItem.node.data.meeting_link.url}
                            //meetingLinkText={meetingItem.meeting_link_text.text}
                            postLink={meetingItem.node.url}
            />
                    )
                })}
            
            </Container>
        </Container>
        </>
    )
}

export default ConferencesBlock
import React from 'react'
import {
    Box,
    Container,
    Flex,
    Heading,
} from '@chakra-ui/react'

import { useStaticQuery, graphql } from 'gatsby'
import TeamMember from './TeamMember'

const TeamBlock = () => {
    const data = useStaticQuery(graphql`
    {
      allPrismicTeam {
        edges {
          node {
            data {
              team_member {
                team_member_bio {
                  raw
                  text
                  html
                }
                team_member_name {
                  text
                }
                team_member_photo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 315
                    layout: CONSTRAINED
                    height: 409
                    placeholder: BLURRED
                    transformOptions: {cropFocus: ATTENTION}
                      )
                    }
                  }
                }
                team_member_title {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  const teamMember = data.allPrismicTeam.edges[0].node.data.team_member

  return (
    <Box my="20">
        <Container maxW="container.xl">
        <Heading as="h2" textAlign={{sm: 'center', md: 'left'}} id="leadership">
            The Team Behind IRIS Biomedical
        </Heading>
        <Flex flexWrap="wrap">
            {teamMember.map((person) => {
                return (
                    <TeamMember 
                        teamMemberName={person.team_member_name.text}
                        teamMemberTitle={person.team_member_title.text}
                        image={person.team_member_photo.localFile}
                        modalContent={person.team_member_bio.raw}
                    />
                )
            })}
        </Flex>
        </Container>
    </Box>
    )
}

export default TeamBlock
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box, Flex, Text } from "@chakra-ui/react"
import { RichText } from "prismic-reactjs"


const AboutImages = () => {

  const data = useStaticQuery(graphql`
    {
      allPrismicAboutPage {
        edges {
          node {
            data {
              company_info_title {
                text
              }
              company_info_text {
                raw
              }
              company_info_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, formats: AUTO)
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  `)

  const document = data.allPrismicAboutPage.edges[0].node.data

  
  return (
    <Box my="20">
    <Flex display={{ sm: "none", md: "flex" }} flexWrap="wrap">
      <Box w={{ sm: "100%", md: "40%" }}>
        <Flex
          flexGrow="1"
          flexDirection="column"
          p="2"
          bg="steel.700"
          height="100%"
        >
          <Box w="100%" h="100%" position="relative">
            <Box
              right="0"
              top="50%"
              w="600px"
              position="absolute"
              style={{ transform: "translate(10%, -50%)" }}
            >
              <GatsbyImage
              image={getImage(document.company_info_image.localFile)} alt={document.company_info_image.alt}
            />
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box w={{ sm: "100%", md: "60%" }}>
        <Flex
          flexDirection="column"
          p="2"
          bg="shadow.100"
          px="20"
          py="16"
          height="100%"
        >
          <Text fontSize="1.5rem" textTransform="uppercase" color="steel.700" fontWeight="700">
            {document.company_info_title.text}
          </Text>
          <RichText render={document.company_info_text.raw} />
        </Flex>
      </Box>
    </Flex>

    {/* Mobile display */}
    <Box display={{sm: 'block', md: 'none'}}>
    <Text fontSize="1.5rem" textTransform="uppercase" color="steel.700" px="4" fontWeight="700">
          {document.company_info_title.text}
          </Text>
          <Box  p='2' w="100%">
            <GatsbyImage
              image={getImage(document.company_info_image.localFile)} alt={document.company_info_image.alt}
            />
    </Box>
          <Box px="4">
          <RichText render={document.company_info_text.raw} />
          </Box>
    </Box>
  </Box>   
  )
}

export default AboutImages

import React from 'react'
import {
    Box,
    Container,
    Flex,
    Heading,
    Text
} from '@chakra-ui/react'

import { StaticImage } from 'gatsby-plugin-image'

const AboutImages = () => (
    <Box my="20">
        <Container maxW="container.lg">
            <Flex justifyContent="space-between" flexWrap="wrap">
                <Box w={{sm: '100%', md: '60%'}} p="2" textAlign={{sm: 'center', md: 'left'}}>
                    <Heading as="h2" mb="2">
                    Neurotechnology for Advanced Medical Applications
                    </Heading>
                    <Text fontSize="1.5rem" color="steel.700" fontWeight="300">
                    IRIS Biomedical is a wholly owned subsidiary of Ripple Ventures established to provide neurotechnology engineering, and manufacturing services and technology licensing to the medical device industry.
                    </Text>
                </Box>
                <Box w={{sm: '100%', md: '40%'}} p="2">
                    <StaticImage 
                        src="../../images/022019-MIRA-6-whitebg.jpg"
                        alt="MIRA 6"
                        placeholder="blurred"
                    />
                </Box>
            </Flex>
        </Container>
    </Box>
)

export default AboutImages